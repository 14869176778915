exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cartons-js": () => import("./../../../src/pages/cartons.js" /* webpackChunkName: "component---src-pages-cartons-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-customer-service-js": () => import("./../../../src/pages/customer-service.js" /* webpackChunkName: "component---src-pages-customer-service-js" */),
  "component---src-pages-disposal-js": () => import("./../../../src/pages/disposal.js" /* webpackChunkName: "component---src-pages-disposal-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logistics-js": () => import("./../../../src/pages/logistics.js" /* webpackChunkName: "component---src-pages-logistics-js" */),
  "component---src-pages-professional-js": () => import("./../../../src/pages/professional.js" /* webpackChunkName: "component---src-pages-professional-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-recruitment-js": () => import("./../../../src/pages/recruitment.js" /* webpackChunkName: "component---src-pages-recruitment-js" */),
  "component---src-pages-responsibility-js": () => import("./../../../src/pages/responsibility.js" /* webpackChunkName: "component---src-pages-responsibility-js" */),
  "component---src-pages-safe-js": () => import("./../../../src/pages/safe.js" /* webpackChunkName: "component---src-pages-safe-js" */),
  "component---src-pages-storage-js": () => import("./../../../src/pages/storage.js" /* webpackChunkName: "component---src-pages-storage-js" */),
  "component---src-pages-system-js": () => import("./../../../src/pages/system.js" /* webpackChunkName: "component---src-pages-system-js" */)
}

